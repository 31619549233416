import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import styles from "./MobileMenuActivator.module.css";

interface MobileMenuActivatorProps {
  toggleNavbar: () => void;
  isNavbarOpen: boolean;
}
function MobileMenuActivator({
  toggleNavbar,
  isNavbarOpen,
}: MobileMenuActivatorProps) {
  return (
    <button
      aria-label="show or hide navbar"
      className={`${styles.mobileMenuActivatorBtn} mobile-menu-activator`}
      onClick={toggleNavbar}
    >
      {isNavbarOpen ? <RxCross2 size="2em" /> : <RxHamburgerMenu size="2em" />}
    </button>
  );
}

export default MobileMenuActivator;
