import type { ParsedMenuDataItem } from "astro-helpers/src/MenuOrganizer";

import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { IoChevronForwardOutline } from "react-icons/io5";

import styles from "./MobileTopItemAccordion.module.css";

interface Props {
  heading: string;
  items: ParsedMenuDataItem[];
}

function MobileTopItemAccordion({ heading, items }: Props) {
  return (
    <Accordion className={styles.accordion} transition transitionTimeout={250}>
      <AccordionItem
        header={({ state }) => (
          <>
            <span>{heading}</span>
            <IoChevronForwardOutline
              className={
                state.isEnter
                  ? `${styles.chevron} ${styles.chevronActive}`
                  : styles.chevron
              }
            />
          </>
        )}
        className={styles.accordionItem}
        headingProps={{ className: styles.accordionHeading }}
        buttonProps={{ className: styles.accordionButton }}
        contentProps={{ className: styles.itemContent }}
      >
        <ul className={styles.ul}>
          {items.map((item) => {
            return (
              <li key={item.id} className={styles.li}>
                <a className={styles.link} href={item.link}>
                  {item.item}
                </a>
              </li>
            );
          })}
        </ul>
      </AccordionItem>
    </Accordion>
  );
}

export default MobileTopItemAccordion;
