import type { ParsedMenuData } from "astro-helpers/src/MenuOrganizer";
import type { NavbarState } from "../NavbarMenu/NavbarMenu";
import styles from "./NavLinks.module.css";
import { useEffect, useMemo, useState } from "react";
import MobileTopItemAccordion from "./MobileTopItemAccordion";

interface NavLinksProps {
  navbarState: NavbarState;
  menuData: ParsedMenuData;
}

function placeBlueBar(isNavbarOpen: boolean) {
  const isInTarget = window.matchMedia("(max-width: 1600px)").matches;

  if (isInTarget && isNavbarOpen) {
    const blueBar2 = document.querySelector<HTMLDivElement>(
      `.${styles.blueBar2}`,
    );
    const subItems = document.querySelectorAll<HTMLDivElement>(
      `.${styles.linksHeading}`,
    );

    if (blueBar2 && subItems && subItems.length > 3) {
      const subItem = subItems[3]; // Target the specific subItem
      const navbar = document.querySelector(".navbar")!;
      console.log(subItem);
      const locationBar = document.querySelector(".location-bar")!;
      const locationBarHeight = !locationBar
        ? 0
        : window.scrollY > 0
          ? 0
          : locationBar.getBoundingClientRect().height;

      const topOffset =
        subItem.getBoundingClientRect().y -
        subItem.getBoundingClientRect().height / 2 -
        navbar?.getBoundingClientRect().height -
        locationBarHeight;

      console.log(topOffset);

      blueBar2.style.position = "absolute";
      blueBar2.style.top = `${topOffset}px`; // Place blueBar2 at the top of the item
      blueBar2.style.left = `0`;
      blueBar2.style.width = `100vw`;
    }
  }
}

function NavLinks({
  navbarState: { isNavbarOpen, navbarHeight },
  menuData,
}: NavLinksProps): JSX.Element | null {
  useMemo(() => {
    delete menuData.AuxLinks;
    delete menuData.QuickMenu;
  }, [menuData]);
  const [isMobile, setIsMobile] = useState(false);

  function handleResize() {
    const isMobile = window.matchMedia("(max-width: 1000px)").matches;

    placeBlueBar(isNavbarOpen);

    setIsMobile(isMobile);
  }

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 1000px)").matches;
    setIsMobile(isMobile);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      placeBlueBar(isNavbarOpen);
    }, 400);
  }, [isNavbarOpen]);

  return (
    <div
      id="nav-links"
      className={styles.navLinks}
      style={{
        top: isNavbarOpen ? `${navbarHeight}px` : "-500vh",
        left: 0,
        right: 0,
      }}
    >
      <div className={styles.menuItems}>
        {isMobile ? <h2 className={styles.mobileMenuTitle}>Menu</h2> : null}
        {Object.keys(menuData).map((key) => {
          return isMobile ? (
            <MobileTopItemAccordion
              key={key}
              heading={key}
              items={menuData[key]}
            />
          ) : (
            <ul key={key} className={styles.menuTopItem}>
              <p className={styles.linksHeading}>{key}</p>
              <ul className={styles.subItems}>
                {menuData[key].map((item) => {
                  return (
                    <li className={styles.subItem} key={item.id}>
                      <a href={item.link} className={styles.navLink}>
                        {item.item}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </ul>
          );
        })}
      </div>
      <div role="presentation" className={styles.blueBar} />
      <div
        role="presentation"
        className={`${styles.blueBar} ${styles.blueBar2}`}
      />
    </div>
  );
}

export default NavLinks;
