import { useEffect, useReducer } from "react";
import MobileMenuActivator from "../MobileMenuActivator/MobileMenuActivator";
import NavLinks from "../NavLinks/NavLinks";
import type { ParsedMenuData } from "astro-helpers/src/MenuOrganizer";

import styles from "./NavbarMenu.module.css";

interface NavbarMenuProps {
  organizedMenu: ParsedMenuData;
}
export type NavbarState = {
  isNavbarOpen: boolean;
  navbarHeight: number;
};

enum ActionTypes {
  OPEN = "open",
  CLOSE = "close",
  SET_HEIGHT = "setHeight",
}

function reducer(state: NavbarState, action: any) {
  switch (action.type) {
    case ActionTypes.OPEN:
      return { ...state, isNavbarOpen: true };
    case ActionTypes.CLOSE:
      return { ...state, isNavbarOpen: false };
    case ActionTypes.SET_HEIGHT:
      return { ...state, navbarHeight: action.payload };
    default:
      throw new Error("Invalid action type");
  }
}

const NavbarMenu = ({ organizedMenu }: NavbarMenuProps) => {
  const [state, dispatch] = useReducer(reducer, {
    isNavbarOpen: false,
    navbarHeight: 0,
  });

  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    if (!navbar) return;
    const navbarHeight = navbar?.clientHeight;

    dispatch({ type: ActionTypes.SET_HEIGHT, payload: navbarHeight });
  }, []);

  function toggleNavbar() {
    dispatch({ type: state.isNavbarOpen ? "close" : "open" });
  }

  return (
    <div className={styles.navbarMenu}>
      <MobileMenuActivator
        toggleNavbar={toggleNavbar}
        isNavbarOpen={state.isNavbarOpen}
      />
      <NavLinks navbarState={state} menuData={organizedMenu} />
    </div>
  );
};

export default NavbarMenu;
